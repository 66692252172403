const Environment = require('./Environment');

class AppSettings {
    constructor() {
        this.clientVersion = 1.0;

        this.prodUrl = 'https://dev-api.portone.cloud/api';

        this.sandboxUrl = 'https://dev-api.portone.cloud/api';

        this.baseApiUrl = '';

        this.tokenizationUrl = "https://dev-pci.portone.cloud/api";

        // Chai Test Keys
        // this.secretKey = "sk_test_76d3c611-8dce-42c5-a710-b556f9330d72";
        this.secretKey = "xLtjih4tNwZAP3KWWb5f8H";

        this.jwtToken = "";

        this.debugMode = false;
        this.connectTimeout = 60;
        this.readTimeout = 60;

        this.checkoutURL = 'https://dev-checkout.portone.cloud';
        this.subMerchantKey="";
    }

    setEnvironment(env) {
        switch (env) {
            case Environment.PRODUCTION:
                this.baseApiUrl = this.prodUrl;
                break;
            case Environment.SANDBOX:
                this.baseApiUrl = this.sandboxUrl;
                break;
        }
    }
}

module.exports = new AppSettings();